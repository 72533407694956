<template>
  <div class="has__nav__menu">
    <SearchListBox :query="setApiQuery" :basePath="setBaseURI" :key="setFullPath"/>
  </div>
</template>

<script>
import SearchListBox from "@/components/block/SearchListBox.vue";
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'Saved',
  data() {
    return {
      savedData: {},
      apiQuery: '',
      basePath: '',
    }
  },
  components: {
    SearchListBox,
  },
  computed: {
    ...mapGetters([
      'searchWordHistory'
    ]),
    savedId: function(){
      return this.$route.query.sid
    },
    setApiQuery: function(){
      if(this.savedData[this.savedId]){
        this.apiQuery = this.savedData[this.savedId].apiQuery
      }
      return this.apiQuery
    },
    setBaseURI: function(){
      if(this.savedData[this.savedId]){
        this.basePath = this.savedData[this.savedId].basePath
      }
      return this.basePath
    },
    setFullPath: function(){
      return this.$route.fullPath
    }
  },
  created () {
    this.initialize(this.$route)
    // console.log('Saved SeachList create');
  },
  mounted () {
    // console.log('Saved SeachList mount');
  },
  beforeRouteUpdate(to, from, next) {
    this.initialize(to)
    // console.log('Saved SeachList before route change');
    next();
  },
  beforeDestroy(){
    // console.log('Saved SeachList before Destroy');
  },
  methods: {
    initialize(route){
      for(let i in this.searchWordHistory){
        if(this.searchWordHistory[i].code === route.query.sid){
          this.savedData[route.query.sid] = this.searchWordHistory[i]
        }
      }
    },
  }
}
</script>
